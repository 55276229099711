




















import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { AuthForm } from "@/data/auth";
import ModalFooter from "@/components/common/modalfooter.vue";
import { NotificationFactory } from "@/utils/notificationFactory";

@Component({
  components: { ModalFooter }
})
export default class LoginComponent extends Vue {
  loading: boolean = false;
  @Inject() $validator: any;
  @Prop() form: AuthForm;
  retryTask: number = 0;
  retryCountdown: number = 60;

  created() {
    this.startRetryTask();
  }

  mounted() {
    (this.$refs.code as any).focus();
  }

  async retry(): Promise<void> {
    const request = this.form.prevRequest;

    if (request) {
      await this.form.submit(request).then(() => {
        this.startRetryTask();
      });
    }
  }

  codeInput(e: Event) {
    if (this.loading) return;
    if (e && e.srcElement) {
      let inputValue: string = (e.srcElement as HTMLInputElement).value;
      if (inputValue.length === 6) {
        this.form.smsConfirmationCode = inputValue;
        this.$nextTick(() => this.submit());
      }
    }
  }

  validate(): boolean {
    if (!this.form.smsConfirmationCode.match(/^\d{6}$/)) {
      NotificationFactory.error(this.$i18n.t("notifications.code6dig"));
      (this.$refs.code as any).focus();
      return false;
    }
    return true;
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      await this.form.submit().then(nextRoute => {
        this.$router.push({ name: nextRoute });
      });
    } finally {
      this.loading = false;
    }
  }

  startRetryTask(): void {
    clearInterval(this.retryTask);
    this.retryCountdown = 60;
    this.retryTask = setInterval(() => {
      if (this.retryCountdown !== 0) {
        this.retryCountdown -= 1;
      } else {
        clearInterval(this.retryTask);
      }
    }, 1000);
  }

  get btnLabel(): string {
    return this.retryCountdown === 0
      ? (this.$i18n.t("notifications.retry") as string)
      : `${this.$i18n.t("notifications.retryAfter") as string} ${
          this.retryCountdown > 9
            ? this.retryCountdown
            : "0" + this.retryCountdown
        } ${this.$i18n.t("notifications.sek") as string}`;
  }

  get btnClasses(): string {
    return this.retryCountdown === 0 ? "btn_secondary btn_md" : "btn_md";
  }

  get btnStyle(): string {
    return this.retryCountdown === 0 ? "" : "pointer-events: none";
  }

  beforeDestroy() {
    clearInterval(this.retryTask);
  }
}
